import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { format, addDays, addMonths, parseISO, formatISO, toDate, getYear, getMonth, isDate, compareAsc, sub, addYears,
         setMilliseconds, setSeconds, setMinutes, setHours, getMilliseconds, getSeconds, getMinutes, getHours, getDaysInMonth } from 'date-fns';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root' //'any'
})

export class HelperClassService {
  minDate = new Date(-8640000000000000);
  maxDate = new Date(8640000000000000);  
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)

  constructor(private userService: UserService, private datePipe: DatePipe) { }

  public getEmptyGuid = () => '00000000-0000-0000-0000-000000000000';

  // If Z is returned at the end of a Date that is in string format then Date FNS parse ISO uses the Z and substract the hours from the timezone your in which can move the date to a day earlier.
  public removeZfromTimezone(date: string) {
    if (!!date) {
      return date.replace('Z', '');
    }
    return date;
  }

  public getDateOrEmpty(date: string) {
    var parseDate = parseISO(this.removeZfromTimezone(date));
    if (!parseDate || isNaN(parseDate.getDate()))
      return null;
    else
      return parseDate;
  }
  
  public removeTimeFromDate(value: Date) {
    return toDate(value.setHours(0, 0, 0, 0));
  }

  public castToDate(value: any) {
    if (typeof value == 'string')
      return parseISO(this.datePipe.transform(value, 'YYYY-MM-dd')); // parseIso needs YYYY-MM-dd format
    else
      return toDate(value);
  }

  public castToDateString(value: any, dateFormat: string = 'MM-dd-yyyy') {
    if (typeof value == 'string')
      return this.datePipe.transform(value, dateFormat);
    else if (this.isValidDate(value))
      return format(value, dateFormat); 
    else
      return '01-01-1900';
  }

  public castToDateWithNoTime(value: any, setDefaultIfMissing: boolean = false) {
    if (setDefaultIfMissing && !value) value = this.originDate;
    return this.removeTimeFromDate(this.castToDate(value));
  }

  public isValidDate(value: any) {
    var dt = this.castToDateWithNoTime(value);
    if (!dt || compareAsc(dt, this.originDate) == 0) return false;

    return true;
  }

  public isExpired(value: any) {
    var dt = this.castToDateWithNoTime(value ?? this.originDate);
    if (!!dt && compareAsc(dt, this.originDate) != 0) return true;

    return false;
  }

  public convertToDate(dateString: string, format: string = "YYYY-MM-DD"): Date {
    if (format.toUpperCase() == "MM-DD-YYYY") 
      return new Date(dateString.slice(0, 2) + '-' + dateString.slice(3, 5) + '-' + dateString.slice(6, 10));
    else if (format == "YYYY-MM-DD HH:mm")
      return new Date(dateString.slice(5, 7) + '-' + dateString.slice(8, 10) + '-' + dateString.slice(0, 4) + ' ' + dateString.slice(11, 13) + ':' + dateString.slice(14, 16));
    else
      return new Date(dateString.slice(5, 7) + '-' + dateString.slice(8, 10) + '-' + dateString.slice(0, 4));
  }

  public compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  } 
  
  public formatPhoneNumber(phoneNumber: string){
    var newPhoneNumber = phoneNumber.trim();
    if (phoneNumber.length == 10) {
      newPhoneNumber = "(" + newPhoneNumber.substring(0, 3) + ")" + newPhoneNumber.substring(3, 6) + "-" + newPhoneNumber.substring(6, 10);
    }
    else if (phoneNumber.length == 7) {
      newPhoneNumber = newPhoneNumber.substring(0, 3) + "-" + newPhoneNumber.substring(3, 7);
    }
    return newPhoneNumber;
  }

  isStringValidDate(date: string) {
    if (!date) return false;

    var parseDate = parseISO(this.removeZfromTimezone(date));
    if (isNaN(parseDate.getDate())) return false;

    return true;
  }

  getContractStartDate(date: string, numberYearsInFuture: number) {
    return this.castToDateString(addYears(this.castToDateWithNoTime(date), numberYearsInFuture));
  }

  getContractEndDate(date: string, numberYearsInFuture: number) {
    var currentDate = this.castToDateWithNoTime(date);
    var endDate = addYears(currentDate, numberYearsInFuture);
    // this will account for leap year for a Feb end date
    var daysDiff = getDaysInMonth(endDate) - getDaysInMonth(currentDate);
    if (daysDiff > 0) endDate = addDays(endDate, daysDiff);
    
    return this.castToDateString(endDate);
  }

  // used to convert API string date to frontend date as a string to use in HTML
  getFormattedDate = (date: string) => (!this.isStringValidDate(date)) ? '' : format(parseISO(this.removeZfromTimezone(date)), 'MM/dd/yyyy');
  getFormattedDateString = (date: string, dateFormat: string = 'MM-dd-yyyy') => (!this.isStringValidDate(date)) ? '' : format(parseISO(this.removeZfromTimezone(date)), dateFormat);
  isDateDefaultOrEmpty = (date: string) => !date || this.getFormattedDateString(date) == '01-01-1900';

  getFormattedDateTime = (date: string) => (!this.isStringValidDate(date)) ? '' : format(parseISO(this.removeZfromTimezone(date)), 'MM/dd/yyyy hh:mm:ss aa');

  // used to send formatted date string to backend API
  getISODateString = (date: Date) => (!this.isValidDate(date)) ? null : formatISO(date, { representation: 'date' });

  // converts API string to a date for PrimeNG controls
  getISODate = (date: string) => (!this.isStringValidDate(date)) ? null : parseISO(this.removeZfromTimezone(date));

  getErrors(error) {
    return error?.error?.data ?? error?.error?.message ?? error?.error ?? 'unknown error';
  }

  displayError(messageService: MessageService, message: string, error: any, showDetails: boolean) {
    var detailedError = this.getErrors(error);
    messageService.add({severity: 'error', summary: 'Failure', detail: message + (showDetails ? ' ' + this.getErrors(error) : ''), life: 0, sticky: true});
    this.userService.LogError(message + ' ' + detailedError);
  }

  public getMonths()
  {
    return [{name:"Jan", code:"1"},
            {name:"Feb", code:"2"},
            {name:"Mar", code:"3"},
            {name:"Apr", code:"4"},
            {name:"May", code:"5"},
            {name:"Jun", code:"6"},
            {name:"Jul", code:"7"},
            {name:"Aug", code:"8"},
            {name:"Sep", code:"9"},
            {name:"Oct", code:"10"},
            {name:"Nov", code:"11"},
            {name:"Dec", code:"12"}];
  }

  public getYears(numberOfPastYears?: number)
  {
    var years = [] as string[];
    var currentYear = new Date().getFullYear();

    if (!numberOfPastYears) numberOfPastYears = 10; // default to past 6 years
    for (let i = 0; i < numberOfPastYears; i++) {
      years.push(currentYear.toString());
      currentYear = currentYear - 1;
    }

    return years;
  }  
}
