import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse, LoginStatus, UserModel } from './login-models';
import { isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, forkJoin, lastValueFrom, shareReplay } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { BroadcastService } from 'src/app/services/broadcast/broadcast.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
    // URL which returns list of JSON items (API end-point URL)
    private readonly URL = environment.apiURL + '/login';
    private env = environment;
    private userData:UserModel;
    private loginStatus:LoginStatus;
    private lastLoginRequest: LoginRequest
    public hasError:boolean = false;
    public errorMessage:string = "";
 
    private currentUserSource = new ReplaySubject<UserModel>(1);
    public currentUser$ = this.currentUserSource.asObservable();
    private currentLoginStatusSource = new ReplaySubject<LoginStatus>(1);
    public currentLoginStatus$ = this.currentLoginStatusSource.asObservable();
    
    loggedin:boolean = false;
    currentUser: string;
    response: LoginResponse;
    typeSignalLogout:'LogoutSignal'

    constructor( private http: HttpClient,
                 private router: Router,
                 private commonDataService: CommonDataService,
                 private providerService: ProviderService,
                 private userService: UserService,                 
                 private authService: AuthService,
                 private logging: LoggingService,
                 private broadcastService: BroadcastService
                 ) 
      {
         this.broadcastService.messagesOfType(this.typeSignalLogout).subscribe(result => {
                this.logout(false);
         });
      }

    public getLoginStatusObservable(){

    }

    public setLoginStatus(loggedin:boolean, expired:boolean, expiredDate:Date, userLogout:boolean){
       this.loginStatus =  {
              expired:expired,
              request : this.lastLoginRequest ?? null,
              token : this.authService.getToken() ?? "",
              loggedIn : loggedin,
              userLogout: userLogout ?? false
              }   
      this.loggedin = loggedin;
      this.currentLoginStatusSource.next(this.loginStatus);
    }

    public login( request:LoginRequest   ) 
    { 
      const headers = new HttpHeaders().set("X-CustomHeader", "custom header value");
      let url  = this.URL + "/login" ;   
      const self = this;
      return new Observable ( observer => {
        this.http.post(url, request).subscribe( {
          next: async ( response:LoginResponse )=> {
            self.userData  = response.userData;
            self.response = response;
            if (self.response.errorOcurred)
            {
                self.loggedin = false;
                self.setLoginStatus(false, false, null, false);
            }
            else
            {
              if (self.userData ){
                //userData.lastLogin =   Date.    // this.datePipe.transform(this.myDate, 'yyyy-MM-dd');  // DATE PROBLEM
                localStorage.setItem('champ-user-data', JSON.stringify(self.userData))
                self.lastLoginRequest = { ...request}; // Copy all properties of object from one to another 
                self.loadServices(self.userData.userID).subscribe( {
                        next: () => {
                          if (self.userService.hasError) {
                            self.setLoginStatus(false, false, null, false);
                          }
                          else
                          {
                            self.loggedin = true;
                            self.setLoginStatus(true, false, null, false);
                            self.currentUserSource.next(self.userData); // Signal Login menu
                          
                          }                       
                          observer.next(); 
                        },
                        error: (error) =>  { 
                            self.LogError(error); 
                            self.setLoginStatus(false, false, null, false);
                            observer.error(error);
                        },
                        complete:()=> {
                          //observer.next();
                        }
                });
              }
            }
            //observer.next(response);
          },
          error: (err)=> {
            this.LogError(err);
            let errmsg: string = "";

            // parse error messages
            switch   (err.name){
              case "HttpErrorResponse":
                  if (err.error) errmsg =  err?.error;
                  else if (err.message) errmsg =  err?.message;
                  else if (!err.error)
                      errmsg = "Failed to connect to CHAMP Server \n"
                  else
                      errmsg = "Failed to connect to CHAMP Server" ;
                break;

              default:
                if ( self.userService.hasError) 
                errmsg = self.userService.errorMessage;
              else {                   
                if ( self.authService.errorMessage?.length > 0)  errmsg = self.authService.errorMessage;
                else if ( err?.error?.errorMessage?.length > 0)  errmsg = err.errorMessage;
                else if ( err?.message )  errmsg = err.message;
                else if ( err )  errmsg = err;
              }
            }
            observer.error(errmsg);
          } ,  
          complete:async () => {
           // observer.complete();

        }
        });   ;
      }).pipe(shareReplay());
    }
    

    public validate(auth:any, userName?:string, password?:string):Observable<LoginResponse>
    {

      this.authService.auth = auth;
      //if (!userName) userName = "learyd@futurebridge.net";
     // if (!password) password = "Dallas99";
     // if (isDevMode()) this.loggedin = false;
     // this.authService.SignIn(userName, password);
      if( this.authService.isUserAuthenticated){
            // if ( !this.authService.IsEmailVerified) {
            //     //this.emailVerified = false;
            //     //this.spinnerService.Off();  
            // }
            // else
            // {
              if ( !this.loggedin ) {
                    return new Observable ( observer => {
                      const request:LoginRequest = { email:userName, password:password };
                      this.login(request).subscribe({     // ISSUE HERE 
                        next: (resp:LoginResponse) => {observer.next(resp) }, 
                        error: (error) => {console.log(error); observer.error(error);}, 
                        complete: ()=> { observer.complete();
                      }
                    });
                  }
                );
              }
              else 
              {
                 return new Observable ( observer => {observer.next()} );
              }
            // }
        }
     }
    
     public validateNew(){

      let valSubject = new BehaviorSubject<boolean>(false);
      let validateObservable$: Observable<boolean> = valSubject.asObservable();
      if (this.env.environmentName == 'LOCAL' || this.env.environmentName == 'DEV')
      {
        let userName = this.env?.debugUser;
        let password = this.env?.debugPwd ;
        if (isDevMode()) this.loggedin = false;

        this.authService.SignIn(userName, password).then( x=>   {
          if(this.authService.isUserAuthenticated){
              const request:LoginRequest = { email:userName, password:password };
              this.login(request).subscribe({
                    next: (resp) => { valSubject.next(false);}, 
                    error: (error) => {console.log(error); valSubject.error(error);}, 
                    complete: ()=> { 
                      valSubject.complete();
                    }
                  });
                }
            }   
        );
      }
      else{
        valSubject.next(false);
        valSubject.complete();
      }
      return validateObservable$;
     }


    public loadServices (userID:number) {
      return new Observable ( observer => {
          forkJoin({
                  _user: this.userService.load(userID),
                  _common: this.commonDataService.loadAllData()
                })
                .subscribe( {
                    next: ( {_user, _common}) => { 
                      this.providerService.load(this.userService.CurrentProviderID)
                      .subscribe( {
                        next: (res) => { res => observer.next(res); },
                        error: (err) => { 
                            console.log (err); observer.error(err); 
                          },
                        complete: () => { observer.next(); }
                      })

                    },
                    error: (err) => { 
                           observer.error(err);
                        },
                    complete: () => {
                         //console.log("loadServices forkJoin completed data exists " + !!this.providerService.data);
                         //observer.complete(); 
                    }
              }  );
        });
    }

    public setCurrentUser(user:UserModel){
      //this.loggedin = true;
      this.currentUserSource.next(user);
    }

    public async standAloneLogin() {
      var user = "learyd@futurebridge.net";
      var password = "Dallas99";
      try {

        // if (environment.environmentName == 'LOCAL' || environment.environmentName == 'DEV')
        // {
        //     await this.authService.SignIn(user, password);
        //     if(this.authService.isUserAuthenticated) {
        //         // this.validate(user, password).subscribe({
        //         //                   next: (resp) => { console.log(resp)
        //         //                   },
        //         //                   error: (error) => { console.log(error) },
        //         //                   complete: () => { 
        //         //                         return true;
        //         //                   }});

        //        await lastValueFrom( await this.validate(user, password));
        //        return true;
        //     }
        //  }
        return true;
      }
      catch (error) {
        console.error(error);
        return false;
      }
    }
    
    public logout(initiateLogout:boolean = true){
      // initiateLogout will be the defaault to send signals to the other open tabs, secondairy tabs will be closed with the false option via the 
      this.setLoginStatus(false,false,null, true);
      this.loggedin = false;

      if (initiateLogout) this.broadcastService.publish({type:this.typeSignalLogout, payload:'LogoutTabs'});

      localStorage.removeItem('champ-user-data');
      this.authService.SignOut();
      this.userService.unload();
      this.currentUserSource.next(null);
      this.providerService.unload();
    }

    public LogInformation(message){
      this.logging.LogInformation(message);
    }

    public LogError(message){
      this.logging.LogError(message);
    }
    
    public async LogWarning(message){
      await lastValueFrom(this.logging.LogWarning(message));
    }

    public LogDebug(message){
      this.logging.LogDebug(message);
    }
}
