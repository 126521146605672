import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayManagerService {

  activeOverlays: any[] = [];
  
  constructor() { }

  public remove(overlay) {

    this.activeOverlays.pop();
  }


  public add(overlay, type:string) {
    overlay.type = type
    this.activeOverlays.push(overlay);
  }

  public closeAll() {
    this.activeOverlays.reverse().forEach( overlay => {
      if (overlay.type == 'overlay'){
         overlay.hide(overlay);
      }
      else if (overlay.type == 'dialogRef' ){
        overlay.close();
      }
        
    })
  }

 

}
