import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest, interval, Observable } from 'rxjs';
import { ProviderDataModel, ProviderInfoDataModel, ReferralProvidersModel, ExternalProvidersModel, PostResponse, ProviderModel } from '../models/provide.model';
//import { map } from 'rxjs/operators';
import { HelperClassService } from '../services/helper-class.service';
import { UserService } from 'src/app/services/user.service';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, 
         sortedUniq, sortBy, orderBy, map, merge, uniqBy } from 'lodash';
import { CommonDataService } from '../services/common-data.service';

@Injectable({
  providedIn: 'root'
})

// We will add code here to manage all the provider information, options, contracts, clinics
export class ProviderService {
  //private readonly URL = environment.apiURL + '/provider/service';
  private readonly URL = environment.apiURL + '/provider';
  public data: ProviderDataModel;
  public providerID:number;
  public loaded:boolean = false;


  private providerSubject = new BehaviorSubject<ProviderInfoDataModel>(null);
  public provider$: Observable<ProviderInfoDataModel> = this.providerSubject.asObservable();
 
  constructor(private http: HttpClient, private helperClass: HelperClassService,
              private userService: UserService, private commonDataService: CommonDataService) {
  }

  public load(providerID:number): Observable<ProviderDataModel>
   {
     this.providerID = providerID;
      
     return new Observable ( observer => {
         this.getData().subscribe( {
          next:(data: ProviderDataModel) => {   
             //console.log(data);
             this.data = data;
             const prov: ProviderInfoDataModel =  { provider : data.provider, clinic: data.clinics.find(d => d.id == this.userService.CurrentClinicID) };
             this.providerSubject.next(prov);
             observer.next();
         },
         error: (error) => {
              console.log("ProviderService load");
             console.log(error);
             //observer.error(error);
         },
         complete: () => {
           this.loaded = true;
           
           observer.complete();
         }});
     });
   }

   unload() {
    this.data = null;
    this.providerSubject.next(null);
  }

  private getData () 
  {
    let url  = this.URL + '/service/?provider_id=' + this.providerID?.toString();
    this.loaded = false;

    return this.http.get(url);
  }

  public saveExternalProvider(dsModel: any)
  {
    return this.http.post<PostResponse>(this.URL + '/saveexternalprovider/', dsModel);
  }    

  providerInfoData(): Observable<ProviderInfoDataModel> {
    return this.provider$;
  }

  public getProviders () {
    let url = this.URL + '/getAllProviders';
    return this.http.get<[ProviderModel]>(url);
  }

  public getDefaultClinicID(userID:number): number{
    //let currentClinicID: number = this.data.users.filter(f => f.userId == userID).sort(this.helperClass.compareValues('lastUpdate', 'desc'))[0]?.defaultClinicId;
    //let currentClinicID: number = sortBy(this.data.users.filter(f => f.userId == userID), ['lastUpdate'], ['desc'])[0]?.defaultClinicId;
    let currentList: any = orderBy(this.data.users, ['lastUpdate'], ['desc']);
    return 0;
  }

  public getProviderOptions(){
    if (!!this.data && !!this.data.options)
      return this.data.options[0];
    else
      return null;
  }

  public updateExternalProvider(model: Partial<ExternalProvidersModel>) {
    if (!!this.data.externalProviders) {
      var existingIndex = this.data.externalProviders.findIndex(x => x.id == model.id)
      if (existingIndex >= 0) {
        this.data.externalProviders[existingIndex].sourceProviderId = model.sourceProviderId;
        this.data.externalProviders[existingIndex].name = model.name;
        this.data.externalProviders[existingIndex].phone = model.phone ?? '';
        this.data.externalProviders[existingIndex].address = model.address ?? '';
        this.data.externalProviders[existingIndex].dateTime = model.dateTime;
      }
      else
      {
        this.data.externalProviders.push({ id: model.id, name: model.name, phone: model.phone, address: model.address, 
                                           sourceProviderId: model.sourceProviderId, dateTime: model.dateTime });
      }
    }
  }

  public getExternalProviderById(outsideProviderId: number) {
    if (!!this.data.externalProviders) {
      return this.data.externalProviders.find(f => f.id == outsideProviderId);
    }
    return null;
  }

  public getExternalProviders(includeDefaultValue?: boolean) {
    if (!!this.data.externalProviders) {
      let externalProviders = cloneDeep(this.data.externalProviders
                                                  .map(extProvs => {
                                                                    const extProv: any = { id:parseInt(`${extProvs.id}`),
                                                                                           fullName: this.formatExternalProviderFullName(`${extProvs.name}`,
                                                                                                                                         `${extProvs.phone}`,
                                                                                                                                         `${extProvs.address}`) };
                                                                    return extProv;})
                                        );
      if (includeDefaultValue) externalProviders.splice(0, 0, { id: 0, fullName: '-- Select a value --' });
      return externalProviders;
    }
    else return null;
  }

  private formatExternalProviderFullName(name:string, phone:string, address:string) {
    var fullName = name + ' [ ' + (phone.trim() == '' ? 'No Phone #' : this.helperClass.formatPhoneNumber(phone)) + ' ] [ ' + (address.trim() == '' ? 'No Address' : address.trim()) + ' ] ';
    return fullName;
  }

  public getReferralProviders(serviceID:number, subTypeId:number, dateRange:Date, includeDefaultValue?: boolean) {
    let groupEntryIds: Array<number> = [ 999 ];
    let isGroupEntry = this.commonDataService.getSubTypes().filter(f => f.id == subTypeId)[0]?.groupEntry;
    if (isGroupEntry) groupEntryIds.push(777); else groupEntryIds.push(888);

    let refProviders = sortBy(cloneDeep(this.data.referralProviders
                                                  .filter((f:ReferralProvidersModel) => 
                                                               { if (f.serviceId == serviceID &&
                                                                    (f.subId == subTypeId || groupEntryIds.includes(f.subId)) &&
                                                                    (dateRange.getTime() >= this.helperClass.convertToDate(f.contractStartDate).getTime() && 
                                                                     dateRange.getTime() <= this.helperClass.convertToDate(f.contractEndDate).getTime())) return true; })
                                                  .map(refProvs => {
                                                                    const refProv: any = {providerId:parseInt(`${refProvs.providerId}`), 
                                                                                          clinicName:`${refProvs.clinicName}`,
                                                                                          clinicId:parseInt(`${refProvs.clinicId}`),
                                                                                          providerName:`${refProvs.providerName}`};
                                                                    return refProv; }
                                                  )), (o) => { return o.clinicName });                                                                     


    // refProviders.splice(0, 0, 
    refProviders.push({ guid: null, providerId: 0, providerName: '*OUTSIDE PROVIDER*  -- ONLY SELECT IF THE CLINIC IS NOT LISTED ABOVE', clinicId: 0, clinicName: '*OUTSIDE PROVIDER*  -- ONLY SELECT IF THE CLINIC IS NOT LISTED ABOVE' });                                                  
    if (includeDefaultValue) refProviders.splice(0, 0, { guid: null, providerId: 0, providerName: '-- Select a value --', clinicId: -1, clinicName: '-- Select a value --' });

    return uniqBy(refProviders, 'clinicId');
  }

  public allowGroupDivideUnits(contractGuid: string, funderId: number, serviceID: number, subTypeId: number) {
    let contractItems = this.getProviderContractItemsByGuid(contractGuid)?.filter(f => f.funder == funderId && f.serviceId == serviceID);
    let contractItem = contractItems?.find(f => f.subId == subTypeId);
    if (!contractItem) {
      contractItem = contractItems?.find(f => f.subId == 777 || f.subId == 999);
    }
    return contractItem?.divideGroupUnits ?? false;
  }

  public getProviderContractsByGuid(contractGuid:string) {
    let baseContracts = this.data.contracts.filter(f => f.guid == contractGuid);
    return baseContracts;
  }

  public getProviderContractItemsByGuid(contractGuid:string) {
    let baseContracts = this.getProviderContractsByGuid(contractGuid);
    let baseContractItems = this.data.contractItems.filter(f => baseContracts.map(ids => { return ids.id }).includes(f.contractId));
    // var merged = map(baseContracts, function(item) { return merge(item, find(baseContractItems, { 'contractId' : item.id })) });
    return map(baseContractItems, function(item) { return merge(item, find(baseContracts, { 'id' : item.contractId })) });
  }  

  public getProviderContractsByDateRange(dateRange:Date) {
    // Den said to add the permission code later
    let bln = this.userService.hasAdminPermission;
    var clinicId = this.userService.CurrentClinicID;
    if (clinicId <= 0) clinicId = this.data?.clinics[0]?.id;
    let baseContracts = this.data.contracts.filter(f => { if (dateRange.getTime() >= this.helperClass.convertToDate(f.startDate).getTime() && 
                                                              dateRange.getTime() <= this.helperClass.convertToDate(f.endDate).getTime() &&
                                                              f.clinicId == clinicId) return true; });
    return baseContracts;
  }

  public getProviderContractItemsByDateRange(dateRange:Date) {
    let baseContracts = this.getProviderContractsByDateRange(dateRange);
    let baseContractItems = this.data.contractItems.filter(f => baseContracts.map(ids => { return ids.id }).includes(f.contractId));
    // var merged = map(baseContracts, function(item) { return merge(item, find(baseContractItems, { 'contractId' : item.id })) });
    return map(baseContractItems, function(item) { return merge(item, find(baseContracts, { 'id' : item.contractId })) });
  }

  public getContractItemBillableCost(contractGuid:string, serviceId:number, subTypeId:number, deliveredUnits:number, dateRange:Date){
    var cost = 0;

    let contractItem = this.getProviderContractItemsByDateRange(dateRange).find(f => f.guid == contractGuid && f.serviceId == serviceId);
    if (!contractItem) return cost;

    if (contractItem.subId == subTypeId || contractItem.subId == 999){
      cost = contractItem.unitCost * deliveredUnits;
    }
    else if (contractItem.subId == 777 && this.commonDataService.isGroupSubType(subTypeId)){
      cost = contractItem.unitCost * deliveredUnits;
    }
    else if (contractItem.subId == 888 && !this.commonDataService.isGroupSubType(subTypeId)){
      cost = contractItem.unitCost * deliveredUnits;
    }

    return Math.round(cost);
  }

  public getProviderContractFunderItems(dateRange: Date, includeDefaultValue?: boolean): any[] {
    let mergedItems = this.getProviderContractItemsByDateRange(dateRange);
    // if not an admin then additional contract filtering is needed to filter user contracts by clinic and ensure user 
    // has permissions to a contract item, Den said to add the permission code later
    let mergedItemsSubset = cloneDeep(mergedItems
                                      .map(cntrs => {
                                            const cntr: any = { keys: { contractGuid:`${cntrs.guid}`, funderId:`${cntrs.funder.toString()}` },
                                                                mergedId: `${cntrs.guid}` + '-' + `${cntrs.funder.toString()}`,
                                                                name: `${cntrs.programName}` + '/' + this.commonDataService.getDescription("Funder", parseInt(`${cntrs.funder}`)) };
                                            return cntr;}
                                            ));

    if (includeDefaultValue) mergedItemsSubset.splice(0, 0, { keys: { contractGuid: '0', funderId: '0' },
                                                              mergedId: '0-0', 
                                                              name: '-- Select a value --' }); 

    return uniqBy(mergedItemsSubset, 'mergedId');
  }

  public getProviderContractList(dateRange:Date): any[] {
    return this.getProviderContractsByDateRange(dateRange)
    .map(cntrs => {
          const cntr: any = {id:parseInt(`${cntrs.id}`), name:`${cntrs.programName}`};
          return cntr;}
          );
  }

  public getProviderEmployeeList(includeDefaultValue?: boolean): any[] {
    var provEmployees = cloneDeep(sortBy(this.data.employees
                                           .map(emps => {
                                                          const emp: any = {id:parseInt(`${emps.id}`), fullName:`${emps.lastName},${emps.firstName}`};
                                                          return emp; }), (o) => { return o.fullName }));

    if (includeDefaultValue) provEmployees.splice(0, 0, { id: 0, fullName: '-- Select a value --' });

    return provEmployees;
  }

  public getProviderPCPsList(): any[] {
    return this.data.employees.filter(e => e.position == 8) // {Physician}
    .map(emps => {
          const emp: any = {id:parseInt(`${emps.id}`), fullName:`${emps.lastName},${emps.firstName}`};
          return emp;}
          );
  }

  public getRequiredField(fieldId: number) {
    return this.data.requiredFields.find(f => f.fieldId == fieldId);
  }

  // Den wanted to find the min expires day occurrence if there are multiple matches
  public getExpiredRequiredField(fieldId: number) {
    return orderBy(this.data.requiredFields, ['expiresDays'], ['asc']).find(f => f.fieldId == fieldId && f.expiresDays > 0);
  }

  public getProviderClinics() {
    return this.data.clinics;
  }

  public isFieldRequired(fieldId: number) {
    return this.data.requiredFields.find(f => f.fieldId == fieldId)?.required ?? false;
  }

}
