import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientStatusFollowUpModel, ClientStatusFollowUpResponseModel, ClientStatusFollowUpUpdateRequestModel, ClientStatusFollowUpUpdateResponseModel } from '../models/client-status-followup';



@Injectable({
  providedIn: 'root'
})
export class ClientStatusFollowupService {


  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/clientstatusfollowup';

  constructor(private http: HttpClient) { }

  public getClientStatusFollowupCount (providerId:number, clinicId:number) 
  {
      var value:string;
      let params = new HttpParams();
      params = params.append('providerId', providerId);
      params = params.append('clinicId', clinicId);
      params = params.append('providerContractGuid', '00000000-0000-0000-0000-000000000000');

      let url  = this.URL  + "/getClientStatusFollowupCount";
      return this.http.get<number>(url, {params:params});
  }

  public getClientStatusFollowup (providerId:number, clinicId:number, providerContractGuid:string) 
  {
      var value:string;
      let params = new HttpParams();
      params = params.append('providerId', providerId);
      params = params.append('clinicId', clinicId);
      params = params.append('providerContractGuid', providerContractGuid);

      let url  = this.URL  + "/getClientStatusFollowup";
      return this.http.get<ClientStatusFollowUpResponseModel>(url, {params:params});
  }

  
  public saveClientStatusFollowup(model: Partial<ClientStatusFollowUpUpdateRequestModel>) {
    let url = this.URL + '/saveClientStatusFollowup';
    return this.http.post<ClientStatusFollowUpUpdateResponseModel>(url, model);
  }  

}
