import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggingService {

  private readonly URL = environment.apiURL + '/logging';

  constructor(private http: HttpClient, private router:Router) {
   
   }

 public LogInformation(message){
    if (message){
      let url  = this.URL +"/add";
      var entry =
      {  severity: "I", 
          message : message
      };
      console.log(message);
      return this.http.post(url, entry);
    }
  }

  public LogError(message){
    if (message){
      let url  = this.URL +"/add";
      var entry =
      {  severity: "E", 
          message : message
      };
      console.error(message);
      return this.http.post(url, entry);
  }
  }
  
  public LogWarning(message){
    if (message){
      let url  = this.URL +"/add";
      var entry =
      {  severity: "W", 
          message : message
      };
      console.warn(message);
      return this.http.post(url, entry);
  }
  }

  public LogDebug(message){
    if (message){
      let url  = this.URL +"/add";
      var entry =
      {  severity: "D", 
          message : message
      };
      console.debug(message);
      return this.http.post(url, entry);
    }
  }
   
}
