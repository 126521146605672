import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { ClientListResponse, clientListRequest } from './client-list-models' 
import { environment } from 'src/environments/environment';
import {  CommonDataModel, CommonData, ServiceCategory, SubType, ServiceSubType, PovertyGuide, ZipCodeResult } from '../models/common-data.model';
import { interval, Observable } from 'rxjs';
//import { observeOn } from 'rxjs/operators';
import { forkJoin, lastValueFrom } from 'rxjs';
//import { map } from 'rxjs/operators';
//import 'rxjs/add/observable/forkJoin';
//import 'rxjs/add/operator/first';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, round,
   sortedUniq, sortBy, orderBy, map, merge, uniqBy, intersection, split, toNumber, isBoolean } from 'lodash';
import { format, addDays, addMonths, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})

export class CommonDataService {

  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/common';
  public data: CommonDataModel;
  
  public loaded:boolean = false;
  public serviceCategories: ServiceCategory[];

  // public data2: any[];
  // public serviceCategories2: any[];
  // public responseData1: object;
  // public responseData2: object;
  // public responseData3: object;

  // We will add code here to get all the common data for app
  constructor(private http: HttpClient) {

   }
  //  public loadAllData2 () 
  //  {
  //       this.getDataFromServers().subscribe(responseList => {
  //         this.responseData1 = responseList[0];
  //         this.responseData2 = responseList[1];

  //     },
  //     error => { console.log ( error )},
  //     () => {console.log ("load complete!")});

  //  }


  //  public getDataFromServers() {
  //     return new Observable ( observer => {
  //       ()=>{
  //           let response1 = this.getAllData();
  //           let response2 = this.loadServiceCategories2();
  //           // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
  //           return forkJoin([response1, response2]); 
  //           }
  //         });
             
  //  }

    public loadAllData () 
    {
        return new Observable ( observer => {
          this.getAllData().subscribe({
                next: (data: CommonDataModel) => {   
                    //console.log(data);
                    this.data = data;
                    observer.next();
                },
                error: (error) => {
                    console.log(error);
                    //observer.error(error);
                },
                complete: () => {
                  this.loaded = true;
                  //this.serviceCategories = this.data.services;

                // var tasks$ = [];
                  // Load other Common Data
                  //this.loadServiceCategories();

                  //Examples 
                  // var values = this.getCommonSetByName("Barrier");
                  // var desc = this.getDescription("Barrier", 2);
                  // var id = this.getID("Barrier", "No contact");

                  // var servies = this.getServiceCatories();
                  // var subtypes = this.getSubTypesByService(81);

                  //var reasons = this.getPaySourceReasons(13);
                  observer.complete();
                }});
      });
    }

    private loadSubTypes(serviceID: number) {

    }

    private loadServiceCategories() {
        let url  = this.URL + '/services';

        this.http.get(url).subscribe({
                        next: (data: ServiceCategory[]) => {this.serviceCategories = data;},
                        error: (error) => {console.log(error);},  
                        complete: ()=> {console.log("Serv Cat Loaded")}});
    }

    public getZipCodeData(zipcode:string):Observable<ZipCodeResult> {
      let url  = this.URL + '/zipcode';

      let params = new HttpParams();
      params = params.append('zipcode', zipcode);

      return this.http.get<ZipCodeResult>(url , { params:params })
    }
    
    private loadServiceCategories2() {
      let url  = this.URL + '/services';

      //  this.http.get(url).subscribe( (data: ServiceCategory[]) => {this.serviceCategories = data;},
      //                   error => {console.log(error);},  ()=> {console.log("Serv Cat Loaded")} )

      return this.http.get(url);
    }

    public async getCurrentDateTime() {
      let url = this.URL + '/CurrentDateTime';
      var currentDateTime = await lastValueFrom(this.http.get<any>(url));
      return parseISO(currentDateTime);
    }

    private getAllData () 
    {
      let url  = this.URL + '/all';
      this.loaded = false;

      return this.http.get(url);
    }

    public getCommonSetByName (setname:string, includeDefaultValue?:boolean, defaultValueName?:string ){
        if (!defaultValueName) defaultValueName = '-- Select a value --';

        if ( !this.loaded )  return null;
        let filterResult =  cloneDeep( sortBy ( this.data.common.filter(e => e.name.toLowerCase() ==  setname.toLowerCase() ), obj => obj.description));

        if ( includeDefaultValue )  {
//            filterResult.splice(0, 0, {name:setname, description: '-- Select a value --', id: 0});
              filterResult.splice(0, 0, {name:setname, description: defaultValueName, id: 0});
        }
        return filterResult;
    }

    public getCommonIdDescriptionSetByName (setname:string, includeDefaultValue?:boolean){
      return this.getCommonSetByName(setname, includeDefaultValue)
                 .map(data => {
                                const result: any = {id:parseInt(`${data.id}`), description:`${data.description}`};
                                return result; });
    }

    public getDescription(setname:string, id:number){
        if ( !this.loaded )  return null;
        return this.data.common.filter(e => e.name.toLowerCase() ==  setname.toLowerCase()  && e.id == id).map(x => x.description);
    }

    public getID(setname:string, desc:string){
      if ( !this.loaded )  return null;
      return this.data.common.filter(e => e.name.toLowerCase() ==  setname.toLowerCase()  && e.description == desc).map(x => x.id);
    }

    public getIDPlusDescription(setname:string, id:number) {
      if (!this.loaded) return null;
      return this.data.common.filter(e => e.name.toLowerCase() == setname.toLowerCase() && e.id == id)
                             .map(data => {
                                          const result: any = {id:parseInt(`${data.id}`), description:`${data.description}`};
                                          return result;}
                                          );
    }

    public getMasterFields() {
      if (!this.loaded) return null;

      return this.data.masterFields;
    }

    public getMasterField(Id: number) {
      return this.getMasterFields().find(e => e.id == Id);
    }

    public getMasterFieldDescription(Id: number) {
      return this.getMasterField(Id)?.description ?? "";
    }

    public getBulkServiceCategories(funderId: number, includeDefaultValue?: boolean, includeOnlyTheseServices?: number[]) {
      var serviceResults = this.getServiceCategories(funderId, includeDefaultValue, includeOnlyTheseServices)?.filter(f => f.bulkEntry || f.id == 0);

      return serviceResults;
    }

    public getGroupServiceCategories(funderId: number, includeDefaultValue?: boolean, includeOnlyTheseServices?: number[]) {
      var serviceResults = this.getServiceCategories(funderId, includeDefaultValue, includeOnlyTheseServices)?.filter(f => f.groupEntry || f.id == 0);

      return serviceResults;
    }    

    public getServiceCategories(funderId: number, includeDefaultValue?: boolean, includeOnlyTheseServices?: number[]) {
      if (!this.loaded) return null;

      var excludedServiceIds = this.getFunderExcludedServices(funderId).map(val => val.serviceId);
      var filteredServiceCategories = this.data.services.filter(f => !excludedServiceIds.includes(f.id));

      if (includeOnlyTheseServices){
        filteredServiceCategories = filteredServiceCategories.filter(f => includeOnlyTheseServices.includes(f.id));
      }

      let serviceResults = sortBy(cloneDeep(filteredServiceCategories), (o) => { return o.description });
      if (includeDefaultValue) serviceResults.splice(0, 0, { id: 0, description: '-- Select a value --', serviceQuery: false, bulkEntry: false, groupEntry: false,
                                                 adhocYesNo: false, coreService: false, rsrId: 0, rsrDescription: null });

      return serviceResults;
    }

    public getFunderExcludedServices(funderId: number) {
      return this.data.funderServiceSubTypeExclude.filter(f => f.funderId == funderId && f.subId == -1);
    }

    public getIncludedSubTypesList(subTypeId: number) {
      return this.getIncludedSubTypes(subTypeId)
      .map(cntrs => {
        const cntr: any = {id:parseInt(`${cntrs.id}`), description:`${cntrs.description}`};
        return cntr;}
        );
    }

    public getIncludedSubTypes(subTypeId: number) {
      if ( !this.loaded )  return null;

      // CHAMP 3.5 accidentally stored the id field (instead of sub_id_included) of master_sub_type_included into the client_direct_services_included sub_type field
      // adjust the sub type fields below as necessary to support the way CHAMP 3.5 did it
      // uncomment original 2 lines below when CHAMP 3.5 goes away and data is migrated to use sub type ids
      // const subTypesIDS = this.data.subTypesIncluded.filter(e => e.subId == subTypeId).map( x=> x.subIdIncluded);
      // let subtypes = this.data.subtypes.filter((e:SubType) => subTypesIDS.includes(e.id));
      // remove the block of code below once CHAMP3.5 is gone and the data in master_sub_type_included sub_id_included field is corrected
      const map = new Map();
      var subtypes = null;
      var subsIncluded = this.data.subTypesIncluded.filter(e => e.subId == subTypeId);
      if (subsIncluded.length > 0) {
        // two way merge of 2 arrays
        // subsIncluded.forEach(item => map.set(item.subIdIncluded, item))
        // this.data.subtypes.forEach(item => map.set(item.id, {...map.get(item.id), ...item}));
        // subtypes = Array.from(map.values());
        for (var subIncluded of subsIncluded) {
          this.data.subtypes.filter(f => f.id == subIncluded.subIdIncluded).forEach(item => map.set(subIncluded.id, { id: subIncluded.id, description: item.description }));
        }
        subtypes = Array.from(map.values());
      }      

      return sortBy(subtypes, obj => obj.description);
    }

    public getServiceCategoryDescription(serviceId: number, allowNullReturn: boolean = false) {
      if (allowNullReturn)
        return this.getServiceCategory(serviceId)?.description;
      else
        return this.getServiceCategory(serviceId)?.description ?? "";
    }

    public getServiceCategory(serviceId: number) {
      return this.data.services.find(e => e.id == serviceId);
    }

    public getSubTypeDescription(subTypeId: number) {
      return this.getSubType(subTypeId)?.description ?? "";
    }    

    public getSubType(subTypeId: number) {
      return this.data.subtypes.find(e => e.id == subTypeId);
    }

    public getSubTypes() {
      if ( !this.loaded )  return null;
      return this.data.subtypes;
    }

    public getBulkSubTypesByService(serviceID: number, funderId: number, includeDefaultValue?: boolean, includeSpecialSubTypes?: boolean) {
      var subtypes = this.getSubTypesByService(serviceID, funderId, includeDefaultValue, includeSpecialSubTypes)?.filter(f => f.bulkEntry || f.id == 0);

      return subtypes;
    }

    public getGroupSubTypesByService(serviceID: number, funderId: number, includeDefaultValue?: boolean, includeSpecialSubTypes?: boolean) {
      var subtypes = this.getSubTypesByService(serviceID, funderId, includeDefaultValue, includeSpecialSubTypes)?.filter(f => f.groupEntry || f.id == 0);

      return subtypes;
    }    

    public getSubTypesByService(serviceID: number, funderId: number, includeDefaultValue?: boolean, includeSpecialSubTypes?: boolean) {
      if (!this.loaded)  return null;

      const subTypesIds = this.data.servicesub.filter(e => e.serviceId == serviceID).map(x => x.subId);
      const excludedSubTypeIds = this.getFunderExcludedSubTypes(funderId, serviceID).map(val => val.subId);
      var filteredSubTypes = this.data.subtypes.filter(e => subTypesIds.includes(e.id) && !excludedSubTypeIds.includes(e.id));

      let subtypes = sortBy(cloneDeep(filteredSubTypes), (o) => { return o.description });

 

      if (includeSpecialSubTypes) {
        var specialSubTypes: Array<any> = []; // = [ { id: 999, description: 'All Sub Types'} ];
        // per meeting with Den/Jay on 12/19/23
        if (subtypes.findIndex(f => f.groupEntry == true) >= 0) {
          specialSubTypes.push({ id: 888, description: 'All Non-Group Sub Types'});
          specialSubTypes.push({ id: 777, description: 'All Group Sub Types' });          
        }
        specialSubTypes.push({ id: 999, description: 'All Sub Types'});

        specialSubTypes.forEach(element => {
          subtypes.unshift({ id: element.id, description: element.description, defaultUnit: null, serviceQuery: false, bulkEntry: false, groupEntry: false,
                             officeVisit: false, adhocYesNo: false, assessmentTypeId: 0, prescribingPrivilege: null, longDescription: null,
                             eisEligible: false, allow2xEncounterBilling: false, allow2xFunderMonthBilling: false });
        });
      }

      if (includeDefaultValue) subtypes.splice(0, 0, { id: 0, description: '-- Select a value --', defaultUnit: null, serviceQuery: false, bulkEntry: false, groupEntry: false,
        officeVisit: false, adhocYesNo: false, assessmentTypeId: 0, prescribingPrivilege: null, longDescription: null,
        eisEligible: false, allow2xEncounterBilling: false, allow2xFunderMonthBilling: false });

      return subtypes; //sortBy(subtypes, obj => obj.description);
    }

    public getFunderExcludedSubTypes(funderId: number, serviceId: number) {
      return this.data.funderServiceSubTypeExclude.filter(f => f.funderId == funderId && f.serviceId == serviceId && f.subId != -1);
    }    

    public getPaySourceReasons(paySourceId: number, includeDefaultValue?: boolean) {
      if ( !this.loaded )  return null;
 
      const reasonIDs = this.data.paySourceToPaySourceReasons.filter(e => e.paySourceId == paySourceId).map( x=> x.paySourceReasonId);
      let reasons:any = cloneDeep(sortBy(this.data.paySourceReasons.filter ( e => reasonIDs.includes(e.id) ), (o) => { return o.description }));
      
      if (includeDefaultValue) reasons.splice(0, 0, { id: 0, description: '-- Select a value --' });

      return reasons;
    }

    // risk reduction functions
    public containsAnyTypeOfRiskReduction(serviceID:number, subTypeId:number){
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && 
                                                 (e.rrTa == true || e.tobacco == true || e.alcohol == true || e.ta == true || 
                                                  e.sa == true || e.mh == true)).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeAllow2xEncounterBilling(subTypeId:number) {
      let cnt = this.data.subtypes.filter(e => e.id == subTypeId && e.allow2xEncounterBilling == true).length;
      return (cnt > 0 ? true: false);      
    }

    public isSubTypeEISEligible(subTypeId:number) {
      let cnt = this.data.subtypes.filter(e => e.id == subTypeId && e.eisEligible == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypePrescribingPrivilege(subTypeId:number) {
      let cnt = this.data.subtypes.filter(e => e.id == subTypeId && e.prescribingPrivilege == true).length;
      return (cnt > 0 ? true: false);
    }

    public isGroupSubType(subTypeId:number) {
      let cnt = this.data.subtypes.filter(e => e.id == subTypeId && e.groupEntry == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeCohortInterventionRequired(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.cohort == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeRiskReduction(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.rrTa == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeTreatmentAdherence(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.ta == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeMentalHealth(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.mh == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeSubstanceAbuse(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.sa == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeTobacco(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.tobacco == true).length;
      return (cnt > 0 ? true: false);
    }

    public isSubTypeAlcohol(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId && e.alcohol == true).length;
      return (cnt > 0 ? true: false);
    }

    public containsAnyRiskReductionCounseling(serviceID:number, subTypeId:number) {
      //isSubTypeMentalHealth - moved to another screen, doesn't belong to risk reduction screen any longer
      //isSubTypeSubstanceAbuse - moved to another screen, doesn't belong to risk reduction screen any longer

      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId &&
                                           (e.rrTa || (e.tobacco ?? false) || (e.alcohol ?? false) || (e.ta ?? false))).length;
      return (cnt > 0 ? true: false);
    }

    public isDirectServicePopUpRequired(serviceID:number, subTypeId:number) {
      let cnt = this.data.servicesub.filter(e => e.serviceId == serviceID && e.subId == subTypeId &&
                                           (e.rrTa || (e.tobacco ?? false) || (e.alcohol ?? false) || (e.ta ?? false) || (e.sa ?? false) || (e.mh ?? false))).length;
      return (cnt > 0 ? true: false);
    }

    public MHScreeningSubTypes: Array<number> = [ 361, 374, 1005, 1007, 1102, 1112, 1125 ];

    public anyMHScreeningSubTypes(subTypeInclusionIds:string) {
      return this.getMHScreeningSubTypes(subTypeInclusionIds)?.length > 0 ? true : false;
    }

    public getMHScreeningSubTypes(subTypeInclusionIds:string) {
      let subTypeInclusionIdArray = split(subTypeInclusionIds, ',').map(value => toNumber(value));
      return intersection(this.MHScreeningSubTypes, subTypeInclusionIdArray);
    }

    public isSubTypeMHScreening(subTypeId:number) {
      return this.MHScreeningSubTypes.includes(subTypeId);
    }

    public isSubTypeSAScreening(subTypeId:number) {
      let list: Array<number> = [ 360, 376, 1004, 1006, 1107, 1111 ];
      return list.includes(subTypeId);
    }

    public isSubTypIncluded4xPer12MonthCheck(subTypeId:number) {
      let list: Array<number> = [ 1014, 1020, 1119, 1131 ]; //349 used for testing
      return list.includes(subTypeId);
    }

    public getMHScreeningIncludedSubTypes(subTypeList:string):Array<number> {
      let list: Array<number> = [ 9, 21, 29 ];
      let subTypeListArray = split(subTypeList, ',').map(value => toNumber(value));
      return intersection(list, subTypeListArray);
    }

    public getSAScreeningIncludedSubTypes(subTypeList:string):Array<number> {
      let list: Array<number> = [ 8, 31, 20 ];
      let subTypeListArray = split(subTypeList, ',').map(value => toNumber(value));
      return intersection(list, subTypeListArray);
    }

    public isSubTypeCarePlan(subTypeId:number) {
      let list: Array<number> = [ 501, 351, 509, 367, 1116, 1122, 1124, 1129 ];
      return list.includes(subTypeId);
    }

    public isFunderRyanWhite(funderId:number) {
      var funder = this.getCommonSetByName('funder').find(f => f.id == funderId);
      if (!!funder && funder.supplementalField1Name == 'IsRyanWhite') {
        if (isBoolean(funder.supplementalField1Value)) return (funder.supplementalField1Value == 'true');
      }
      return false;
    }

    public getPovertyGuides() {
      if (!this.loaded) return null;
      return this.data.povertyGuide;
    }

    public getPovertyLevelPercentage(income:number, familySize:number): number {
      if (income < 0 || familySize <= 0) return 0;

      var today = new Date();
      var currentYear = today.getFullYear();
      var povertyGuidesForGivenYear = this.getPovertyGuides().filter(f => f.year == currentYear);
      // if the new year of poverty guides hasn't been loaded yet then use the data from the prior year, new logic per Den 6/23/2021
      if (!povertyGuidesForGivenYear || povertyGuidesForGivenYear.length <= 0) povertyGuidesForGivenYear = this.getPovertyGuides().filter(f => f.year == currentYear-1);
      var povertyGuide:PovertyGuide = orderBy(povertyGuidesForGivenYear, ['familySize', 'effDate'], ['desc', 'desc'])
                                      .find(f => f.familySize <= familySize && parseISO(f.effDate).getTime() <= today.getTime());
 
      if (!!povertyGuide && povertyGuide.contiguousStates > 0)
      {
          return (income / povertyGuide.contiguousStates);
      }
   
      return 0;
    }    

    public getPovertyLevelCode(income:number, familySize:number): number
    {
        var poverytLevel = 0;
        var decPovRatio =  this.getPovertyLevelPercentage(income, familySize);

        if (decPovRatio == 0)   // return  -1;
            poverytLevel = -1;
        else
        {
            if (decPovRatio <= 1) poverytLevel = 4;  // Equal or Below FPL
            else if (decPovRatio > 1 && decPovRatio < 1.39) poverytLevel = 8;   // 101% - %138 of FPL
            else if (decPovRatio >= 1.39 && decPovRatio <= 2) poverytLevel = 1;  // 139% - %200 of FPL
            else if (decPovRatio > 2 && decPovRatio <= 3) poverytLevel = 2;     // 201% - %300 of FPL 
            else if (decPovRatio > 3 && decPovRatio <= 4) poverytLevel = 3;     // 301% - %400 of FPL
            else if (decPovRatio > 4 && decPovRatio <= 5) poverytLevel = 7;     // 401% - %500 of FPL
            else if (decPovRatio > 5) poverytLevel = 6;                         // > %500 of FPL
        }   
    
        return poverytLevel;
    }

    public getPovertyLevelDescription(income:number, familySize:number)
    {
        var code =  this.getPovertyLevelCode(income, familySize);
        const povertyDescription = this.data.povertyCodes.filter(e => e.povertyCode == code).map( x=> x.povertyDescr).toString();

        return povertyDescription;
    }

    public getPovertyLevelDescriptionWithCode(code:number)
    {
        const povertyDescription = this.data.povertyCodes.filter(e => e.povertyCode == code).map( x=> x.povertyDescr).toString();
        return povertyDescription;
    }

    public getHopwaHousingRates() {
      if (!this.loaded) return null;
      return this.data.hopwaHousingRates;
    }

    public getHopwaConfig() {
      if (!this.loaded) return null;
      return this.data.hopwaConfig?.[0];
    }

    public getHopwaDisability(): number
    {
      return round(this.getHopwaConfig().disability, 2);
    }
    
    public getHopwaTenantPercent(): number
    {
      return round(this.getHopwaConfig().tenantPercent, 2);
    }    

    public getHopwaHousingRate(numRooms: number, county: string, state: string) {
      return this.getHopwaHousingRates().find(f => f.code == numRooms && f.county == county && f.state == state);
    }

}
