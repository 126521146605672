import { Injectable, NgZone } from '@angular/core';
import { User as champuser } from "./auth.user.model";
import { environment } from '../../environments/environment';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getApp } from 'firebase/app';
import { LoggingService } from 'src/app/services/logging.service'
//import { LoginService } from '../login/services/login.service'; 

import { getAuth,
         signInWithEmailAndPassword, 
         createUserWithEmailAndPassword, 
         sendEmailVerification,
         sendPasswordResetEmail,
         GoogleAuthProvider,
         signOut,
         signInWithPopup,
         User as FirebaseUser,
         UserCredential,
         browserSessionPersistence,
         setPersistence,
         browserLocalPersistence
         } from "firebase/auth";


// import { auth } from 'firebase/app';
// import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Router } from "@angular/router";
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public auth: any;
  public firebaseApp: FirebaseApp


  userData: any; // Save logged in user data
  public isUserAuthenticated: Boolean = false;
  public errorMessage: string = "";
  public authMessage: string = "";
 
  private email:string;
  private pwd:string;

  constructor(
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public logging: LoggingService
  ) {    

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null);
    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // })
  
    //const authService = new AuthService(getApp())
  
  }

  getToken(){
      return this?.auth?.currentUser?.accessToken ?? "";
  }

  initializeApp(){

     this.firebaseApp = initializeApp(environment.firebase );

  }

  // Sign in with email/password
  async SignIn(email, password) {
      //     this.SetUserData(result.user);
      
      this.auth = getAuth();
      //setPersistence(this.auth, browserLocalPersistence);
      this.errorMessage = ""; this.authMessage = "";

      await signInWithEmailAndPassword(this.auth, email, password)
                .then((userCredential) => {
                  // Signed in 
                  const user = userCredential.user;
                  // Procede with CHAMP APP Login 
                  this.isUserAuthenticated = true;
                  if (!userCredential.user.emailVerified) {
                    this.errorMessage  = "Email has not been verified.";
                    this.logging.LogError(this.errorMessage);
                    //throw new Error(this.errorMessage);
                  }
                })
                .catch((error) => {
                  this.errorMessage  = error.code + "    " + error.message;
                  this.logging.LogError(this.errorMessage);
                  this.isUserAuthenticated = false;
                });
  }

  // Sign up with email/password
  async SignUp(email, password) {
      this.errorMessage = ""; this.authMessage = "";
      const firebaseApp = initializeApp(environment.firebase);
      this.auth = getAuth();
      await createUserWithEmailAndPassword(this.auth, email, password )
          .then((result) => {
            /* Call the SendVerificaitonMail() function when new user sign up and returns promise */
            return sendEmailVerification(this.auth.currentUser)
            .then(() => {
              console.log("verfication email sent");
              this.logging.LogInformation("verfication email sent");
              
            }).catch((error) => {
              window.alert(error)
              this.logging.LogError(error);
            });
          //  this.SetUserData(result.user);
          }).catch((error) => {
            window.alert(error.message)
            this.errorMessage  = error.code + "    " + error.message;
            this.logging.LogError(this.errorMessage);
          })
  }

  IsAuthenticated(){
      return this.isUserAuthenticated;

     // const firebaseApp = initializeApp(environment.firebase);
     // this.auth = getAuth();

      // var user = this.auth.currentUser;

      // if (user) {
      //     return true;
      // } else {
      //     return false;
      // }


      //  this.auth.onAuthStateChanged(function(user) {
      //   if (user) {
      //     // User is signed in.
      //     return true;
      //   } else {
      //     // No user is signed in.
      //     return false;
      //   }
      // });
 
  }

  async reAuth(){
      this.SignIn(this.email, this.pwd);
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail(user:FirebaseUser) {
      this.errorMessage = ""; this.authMessage = "";
      const firebaseApp = initializeApp(environment.firebase);
      this.auth = getAuth();
      return sendEmailVerification(this.auth.currentUser)
      .then((result) => {
        console.log("verfication email sent");
        this.logging.LogInformation("verfication email sent");
        // this.SetUserData(result.user);
      }).catch((error) => {
        this.errorMessage  = error.code + "    " + error.message;
        this.logging.LogError(this.errorMessage);
      });
      // this.SetUserData(result.user);
    }

  // Resend email verificaiton when new user sign up
  ResendVerificationMail(email, password) {
      this.errorMessage = ""; this.authMessage = "";
      const firebaseApp = initializeApp(environment.firebase);
      this.auth = getAuth();

      this.SignIn(this.email, this.pwd);
      if (this.errorMessage == "")
      {
          return sendEmailVerification(this.auth.currentUser)
          .then((result) => {
            console.log("verfication email sent");
            // this.SetUserData(result.user);
          }).catch((error) => {
            this.errorMessage  = error.code + "    " + error.message;
            this.logging.LogError(this.errorMessage);
          });
      }
  }


  // Reset Forggot password
  sendPasswardResetEmail(email) {
      const firebaseApp = initializeApp(environment.firebase);
      this.auth = getAuth();
      return sendPasswordResetEmail(this.auth, email)
          .then(() => {
            console.log('Password reset email sent, check your inbox.');
            }).catch((error) => {
              this.errorMessage  = error.code + "    " + error.message;
              this.logging.LogError(this.errorMessage);
            });
  }

  // Returns true when user is looged in and email is verified
  // get isLoggedIn(): boolean {
  //   //const user = JSON.parse(localStorage.getItem('user'));
  //   //return (user !== null && user.emailVerified !== false) ? true : false;
  // }

  get IsEmailVerified(): boolean {
    //const user = JSON.parse(localStorage.getItem('user'));

    return ( this?.auth?.currentUser.emailVerified ) ? true: false;

    //return (user !== null && user.emailVerified !== false) ? true : false;
  }


  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new this.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
      return this.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
            //this.router.navigate(['dashboard']);
          })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    // const userData: User = {
    //   uid: user.uid,
    //   email: user.email,
    //   displayName: user.displayName,
    //   photoURL: user.photoURL,
    //   emailVerified: user.emailVerified
    // }
    // return userRef.set(userData, {
    //   merge: true
    // })



  }

  // Sign out 
  SignOut() {
    return this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      //this.router.navigate(['sign-in']);
    })
  }

}