export const environment = {
  firebase: {
    projectId: 'champ4-af4a2',
    appId: '1:1089591724038:web:ac3ffb17fd0932a2ecdb87',
    storageBucket: 'champ4-af4a2.appspot.com',
    apiKey: 'AIzaSyAzmqCSmlB-7mtRlm072y86eY04iRVtfD4',
    authDomain: 'champ4-af4a2.firebaseapp.com',
    messagingSenderId: '1089591724038',
  },
  production: true,
  appVersion: require('../../package.json').version + ' -azure',
  environmentName: 'DEV',
  apiURL: 'https://champ4-dev-api.lemonwave-35a2a4dd.eastus2.azurecontainerapps.io',
  versionCheckURL:'https://champ4-dev-api.lemonwave-35a2a4dd.eastus2.azurecontainerapps.io',
  versionCheckEnabled:true,
  versionCheckInterval:30, // in minutes 

  referralSourceCheckStartDate: '2011-06-15',
  paySourceRequiredStartDate: '2012-04-20',
  rsRSubmissionDeadline: '2019-03-04',
  inactivityTimeout:7200 ,
  messageDisplayTime:10000,
  requiredFields30DayGracePeriod:30,
  requiredFields30DayGracePeriodReset:365,    
  debugUser:'',
  debugPwd:''   
};
