import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientReferralsRequestModel } from '../models/client_referrals.models';


@Injectable({
  providedIn: 'root'
})
export class ClientReferralsService {

    // URL which returns list of JSON items (API end-point URL)
    private readonly URL = environment.apiURL + '/clientreferrals';

    constructor(private http: HttpClient) { }
  
    public PostReferralRequest(model: Partial<ClientReferralsRequestModel>) {
      let url = this.URL + '/postReferralRequest';
      return this.http.post<ClientReferralsRequestModel>(url, model);
    }  
  
}
