export enum RequestType {
    Get,
    Save,
    Delete,
    WaitingCount,
    GetFromClient, 
    SaveTicket, 
    EditReferral,
    UnDelete,
    ChangeStatus
}

export enum ReferralStatusTypes {
    New = 1,
    Accepted = 2,
    Declined = 3,
    InProgress = 4,
    WithDrawn = 5,
    Completed = 6,
    LostToFollowup = 7
}

export enum ReferralCreatedReceived {
    All = 1,
    Created = 2,
    Received = 3
}

export interface ClientReferralsRequestModel {
    requestType: RequestType;
    success: boolean;
    guid: string;
    message: string | null;
    clientGuid: string | null;
    month: number | null;
    year: number | null;
    filterSelectionChoice: number | null;
    dateSelectionChoice: number | null;
    userId: number;
    clinicId: number | null;
    providerId: number | null;
    // sourceClinicId: number | null;
    // sourceProviderId: number | null;
    referralStatus: number | null;
    onlyShowInNetworkReferrals: boolean | null;
    showDeleted: boolean | null;
    referralCreatedReceived: number | null;
    referrals: ClientReferralsModel[] | null;
    waitingCount: number | null;
}

export interface ClientReferralsModel {
    guid: string;
    id: number;
    clientGuid: string;
    serviceId: number;
    subId: number;
    providerId: number;
    confirmStatus: number | null;
    confirmLocation: string | null;
    memo: string | null;
    userId: number;
    datetime: string;
    employeeId: number | null;
    employeeName: string | null;
    referralStatus: number;
    canProvideService: number | null;
    contactedReferrer: boolean | null;
    contactedReferrerDate: string | null;
    willProvideService: number | null;
    contractGuid: string | null;
    funderId: number | null;
    funderName: string | null;
    canProvideRefusal: number | null;
    willProvideRefusal: number | null;
    directServiceGuid: string | null;
    outsideProviderId: number | null;
    destinationProviderId: number | null;
    clinicId: number | null;
    completedDate: string | null;
    completedNotes: string;
    lostToFollowupDate: string | null;
    lostToFollowupNote: string | null;
    clientAltId: string | null;
    providerName: string | null;
    sourceProvider: string | null;
    destinationProvider: string | null;
    referralStatusDesc: string | null;
    serviceDesc: string | null;
    subTypeDesc: string | null;
    referrerName: string | null;
    referrerPhone: string | null;
    referrerEmail: string | null;
    referrerNote: string | null;
    sourceClinicId: number | null;
    deleted: boolean;
}



