import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BroadcastMessage } from './broadcastMessage';
import { environment } from 'src/environments/environment';

 //environment.apiURL 
//import config from '@core/constants/config.json';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  broadcastChannel: any;
  onMessage = new Subject<any>();

  constructor() {
    this.initialize();
  }

  initialize() {
    //const name: string =  environment.BroadcastChannel;   //config.details.detailChangeChannel;
    this.broadcastChannel = new BroadcastChannel("CHAMP4Channel");
    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(
      filter(message => message.type === type)
    );
  }

}