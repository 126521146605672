import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientReferralsService } from '../client-referrals/client-referrals.service';
import { ClientStatusFollowupService } from '../client-status-followup/client-status-followup.service';
import { LoginService } from '../login/services/login.service';
import { UserEnvironment } from '../models/admin.models';
import { ClientReferralsRequestModel, ReferralCreatedReceived, RequestType } from '../models/client_referrals.models';
import { CommonDataService } from '../services/common-data.service';
import { OverlayManagerService } from '../services/overlay-manager.service';
import { ProviderService } from '../services/provider.service';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { LockDetails, RecordLockService, RecordTypes } from '../services/record-lock.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  private readonly URL = environment.apiURL;
  public readonly requiredFields30DayGracePeriod = environment.requiredFields30DayGracePeriod;
  public readonly requiredFields30DayGracePeriodReset = environment.requiredFields30DayGracePeriodReset;
 
  // Services 
  public commonServ = this.commonDataService;
  public userServ = this.userService;
  public loginSvc = this.loginService;
  public provSvc = this.providerService;

  // Environment Variables 
  public messageDisplayTime = environment.messageDisplayTime;

  // Display variables 
  public overLayMangr = this.overlayManager;

  // Observables
  private clientStatusLookupCountSubject = new BehaviorSubject<number>(0);
  public clientStatusLookupCount$: Observable<number> = this.clientStatusLookupCountSubject.asObservable();

  private referralWaitingCountSubject = new BehaviorSubject<number>(0);
  public referralWaitingCount$: Observable<number> = this.referralWaitingCountSubject.asObservable();

  
  constructor(private commonDataService: CommonDataService,
    private userService: UserService,
    private loginService: LoginService,
    private providerService: ProviderService,
    private titleService: Title,
    private http: HttpClient,
    private overlayManager: OverlayManagerService,
    private clientStatusLookupService: ClientStatusFollowupService,
    private referralsService:ClientReferralsService,
    private messageService: MessageService,
    private reclockService: RecordLockService,
    private router: Router
  ) { }

  updateTabTitle(title) {

    this.titleService.setTitle(title);
  }


  openClient(guid:string) {
      // Check if client locked before opening
      this.reclockService.lockAdminReadOnlyMode = false;
      this.reclockService.IsLocked(this.userServ.userID, RecordTypes.client, guid).subscribe( {
          next: ( details:LockDetails ) =>  {
            if (details.locked && !this.userServ.hasAdminPermission) {  // if locked 
              this.messageService.add({severity:'warn', summary: 'Client File Locked', 
                detail: 'Client file is locked by user (' + details.userName + ' - ' + details.userFName + ' ' + details.userLName 
                  + '@  '+ details.provider  + ').  \n Please try again later when user releases the lock.',  life: 6000});
              }
            else if ( details.locked && this.userServ.hasAdminPermission)
              {
                this.messageService.add({severity:'warn', summary: 'Client File Locked', 
                    detail: 'Client file is locked by user (' + details.userName + ' - ' + details.userFName + ' ' + details.userLName 
                      + '@  '+ details.provider  + ').  \n For Admin User Only: Client file will be opened in read only mode.',  life: 6000});
                      this.router.navigate(['../client-form/' + guid]);
                      this.reclockService.lockAdminReadOnlyMode = true;
              }
            else{
                let route  = ['../client-form/${guid}']; 
                this.router.navigate(['../client-form/' + guid]);
            }
          },
          error: (error) =>  {
          }, 
          complete: () => {
            
          }
        });
  }

  public getEnvironmentInfo() {
    let url = this.URL + '/admin/GetEnvironments';
    return this.http.get<UserEnvironment>(url);
   }

  // clientStatusLookupCount(): Observable<number> {
  //   return this.clientStatusLookupCount$;
  // }

  getClientStatusLookupCount() {
      this.clientStatusLookupService.getClientStatusFollowupCount(this.provSvc.providerID, this.userServ.CurrentClinicID).subscribe({
          next:(data) =>{
            this.clientStatusLookupCountSubject.next(data) ;
          },
          error:(error)=>{
            this.clientStatusLookupCountSubject.error(error);
          }
        });
  }

  setClientStatusLookupCount(count) {
    this.clientStatusLookupCountSubject.next(count);
  }

  getReferralsWaitingCount() {
      let model:Partial<ClientReferralsRequestModel> = {};
      Object.assign(model, {});
      model.requestType = RequestType.WaitingCount;
      model.providerId=this.provSvc.providerID;
      model.clinicId = this.userServ.CurrentClinicID;
      model.referralCreatedReceived = ReferralCreatedReceived.Received;

      this.referralsService.PostReferralRequest(model)
      .subscribe({
        next: (data: ClientReferralsRequestModel) => {
          this.referralWaitingCountSubject.next(data.waitingCount) ;
            },
        error: (error) => { 
          this.clientStatusLookupCountSubject.error(error);
          }

        });
  }

}
