import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, first, Observable } from 'rxjs';
//import { filter, first } from 'rxjs/operators';

export enum RecordTypes {
    client = 'client',
    referral = 'referral'
  }

export interface RecordLock {
    userID: number;
    recordType: string;
    guid: string;
    data: string;
}

export interface IsLocked {
    userID: number;
    recordType: string;
    guid: string;
}

export interface ReleaseLock {
    lockID: number;
    userID: number;
    recordType: string;
}

export interface LockDetails {
    locked: boolean;
    lockID: number | null;
    userID: number | null;
    lockDate: string | null;
    data: string | null;
    userName: string | null;
    userFName: string | null;
    userLName: string | null;
    provider: string | null;
}
@Injectable({
    providedIn: 'root'
})

export class RecordLockService {
    private readonly URL = environment.apiURL + '/locking';
    public lockAdminReadOnlyMode:boolean = false;

    constructor(private http: HttpClient) {}
    
    public AddLock(userId, recordType, guid, data){
        let url  = this.URL +"/addlock";
        var request:RecordLock =
        {   userID: userId, 
            recordType : recordType,
            guid: guid,
            data: data
        };
        return this.http.post<any>(url, request);
    }
    
    public ReleaseLock(userId, recordType, lockId){
        let url  = this.URL +"/releaselock";

        var request:ReleaseLock =
        {   userID: userId, 
            recordType : recordType,
            lockID: lockId
        };
        return this.http.post(url, request);
    }

    public IsLocked(userId, recordType, guid){
        let url  = this.URL +"/islocked";
        var request:IsLocked =
        {   userID: userId, 
            recordType : recordType,
            guid: guid
        };
        return this.http.post<any>(url, request);    //.subscribe( locked => { return locked });
    }

    
}