import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, config, interval, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { UserConfigModel, UserConfigInfoModel, UserConfigurationModel, UserConfigurationUpdateModel } from '../models/user.config.model';
import { first, shareReplay } from 'rxjs/operators';
import { ReturnStatement } from '@angular/compiler';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, 
         sortedUniq, sortBy, orderBy, map, merge, uniqBy } from 'lodash';
import { LoggingService } from 'src/app/services/logging.service'
import { AppContextService } from '../app-context/app-context.service';

export interface Option {
  name: string; 
  value: string;
}


@Injectable({
  providedIn: 'root'
})

export class UserService {

  private readonly URL = environment.apiURL + '/user/service';
  public data: UserConfigModel;
  public userID:number;
  public userName:string;
  public hasError:boolean = false;
  public errorMessage:string = "";
  public loaded:boolean = false;
  public CurrentProviderID:number = null; 
  public CurrentClinicID:number = null; 
  public CurrentContract:string = ''; // From Client List  ????
  
  private userSubject = new BehaviorSubject<UserConfigInfoModel>(null);
  public userInfo$: Observable<UserConfigInfoModel> = this.userSubject.asObservable();

  

  constructor(private http: HttpClient, private router:Router, private logging: LoggingService) {
    console.log(this.loaded);

   }

   public load(userID:number) 
   {
     this.userID = userID;
     this.hasError = false; this.errorMessage = ""; 
     return new Observable ( observer => {
         this.getData().subscribe(  {
            
          next: (data: UserConfigModel) => {   
             //console.log(data);
             this.data = data;
             this.userName = data.user.name;
             const usrInfo: UserConfigInfoModel =  { user: data.user, privileges: data.privileges };
             this.userSubject.next(usrInfo);
             observer.next();
         },
         error: (error) => {
             console.log(error);
             observer.error(error);
         },
         complete:() => {
           try {
              this.GetValues();
           }
           catch(error){
            observer.error(error.message);
           }
           this.loaded = true;
           observer.complete();
         }
        });
     }).pipe(shareReplay());
    }

    unload() {
      this.data = null;
      this.userSubject.next(null);
    }

    private getData () 
    {
      let url  = this.URL + '/?user_id=' + this.userID.toString();
      this.loaded = false;
      return this.http.get(url);
    }

    private GetValues(){
      try {

          if (this.data?.userProviders?.length > 0 )
              this.CurrentProviderID = this.data.userProviders[0].providerId;
          else 
            throw Error("User is not assigned to a provider.");

          if (this.data?.userClinics?.length > 0 )
            this.CurrentClinicID = this.data.userClinics.filter(x => x.providerId == this.CurrentProviderID)[0]?.clinicId;
          else 
            throw Error("User is not assigned to a clinic.");
          

          // If Previously saved to options config - reload
          let providerId  = parseInt( this.data.configuration.find(e => e.keyName == 'CHAMP.Registry.ProviderID')?.keyValue);
          if (providerId) { 
              this.CurrentProviderID = providerId;
              let clinicId = parseInt( this.data.configuration.find(e => e.keyName == 'CHAMP4.Registry.ClinicID')?.keyValue);
              if (clinicId) {
                   this.CurrentClinicID = clinicId;  
                  this.CurrentContract =  this.data.configuration.find(e => e.keyName == 'CHAMP.Registry.Filters.LastContractID')?.keyValue;
              }
              else
              {
                this.CurrentClinicID = this.data.userClinics.filter(x => x.providerId == this.CurrentProviderID)[0].clinicId;
              }
          }
      }
      catch (e)
      {
         this.hasError = true;
         this.errorMessage = e.message;
         throw(e);
      }

    }

    public SaveUserConfigValue (keyname: string, value: string)
    {
       const model: UserConfigurationModel = {userId: this.userName, keyName :keyname, keyValue : value, emaId:1};

       let url  = this.URL + "/config"; 
       this.http.post<any>(url, model).subscribe( data => {   
         {
          //console.log( "saved Config Value --> " + model.keyName + " " + model.keyValue);
         }
         error => {
          console.log("ERROR LOG CHAMP4"  + error.messages);
          }
      
       });

    }

    public GetUserConfigValue ( keyname:string)
    {
      var value:string;
      let params = new HttpParams();
      params = params.append('keyname', keyname);
      params = params.append('user_name',this.userName);

      const configSource = new BehaviorSubject<UserConfigurationModel>(null);

       let url  = this.URL + "/config"; 
       this.http.get<UserConfigurationModel>(url, {params:params})
       .subscribe( {
          next: (data) => {   
            value = data.keyValue;
            configSource.next(data);
         },
         error: (error) => {
            console.log("ERROR LOG CHAMP4"  + error);
        },
        complete:() => {
          configSource.complete();
        } 
        }  );

      return configSource.asObservable();

    }

    userInfoData(): Observable<UserConfigInfoModel> {
      return this.userInfo$;
    }

    public get hasAdminPermission() {
      // check if user has access to all providers (-1) and/or just the current provider
      // then check if user is Administrator Privileges, DHHS Privileges, or Provider Supervisor Privileges
      let prms = this.data?.privileges.filter(f => new Array(-1, this.CurrentProviderID).includes(f.providerId) && ['1'].includes(f.privilege.toString())); //'2', '3'
      return (prms && prms.length > 0);
    }

    public get hasDHHSPermission() {
      let prms = this.data.privileges.filter(f => new Array(-1, this.CurrentProviderID).includes(f.providerId) && ['2'].includes(f.privilege.toString()));
      return (prms && prms.length > 0);
    }
  
    public get hasEditPermissions() {
      let prms = this.data?.privileges.filter(f => new Array(-1, this.CurrentProviderID).includes(f.providerId) && ['34'].includes(f.privilege.toString()));
      return (prms && prms.length > 0);
    }
  
    public get hasReadyOnlyPermissions() {
      let prms = this.data.privileges.filter(f => new Array(-1, this.CurrentProviderID).includes(f.providerId) && ['35'].includes(f.privilege.toString()));
      return (prms && prms.length > 0);
    }
  
    public getAccessibleProviders() {
      // Den didn't want to filter out the current provider 10/20/21
      //return this.data.userProviders.filter(val => val.providerId != this.CurrentProviderID);
      return this.data.userProviders;
    }

    public getAccessibleClinics(providerId:number) {
      return uniqBy(this.data.userClinics.filter(val => val.providerId == providerId), 'clinicId');
    }      

    public async LogInformation(message){
      //this.logging.LogInformation(message);
      await lastValueFrom(this.logging.LogInformation(message));
    }

    public async LogError(message){
      //this.logging.LogError(message);
      await lastValueFrom(this.logging.LogError(message));
    }

    public async LogErrorObject(error){
      //this.logging.LogError(message);
      await lastValueFrom(this.logging.LogError(error?.message + "Detail: " + error?.error?.message));
      
    }
    
    public async LogWarning(message){
       // this.logging.LogWarning(message);

      await lastValueFrom(this.logging.LogWarning(message));
    }

    public async LogDebug(message){
      //this.logging.LogDebug(message);
      await lastValueFrom(this.logging.LogDebug(message));
    }
   
}
