import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject,of} from 'rxjs';
import {concatMap, finalize, tap} from 'rxjs/operators';

@Injectable()
export class SpinnerService {

  private spinnerSubject = new BehaviorSubject<boolean>(false);
  public spinning$: Observable<boolean> = this.spinnerSubject.asObservable();

  constructor() { }

  showSpinnerUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null)
        .pipe(
            tap(() => this.On()),
            concatMap(() => obs$),
            finalize(() => this.Off())
        );
  }

  On() {
      this.spinnerSubject.next(true);

  }

  Off() {
      this.spinnerSubject.next(false);
  }
}
