{
  "name": "champ",
  "version": "4.0.134",
  "license": "FutureBridge",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=127.0.0.1",
    "build-dev-azure": "ng build --configuration dev-azure",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.1",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "^17.3.1",
    "@angular/compiler": "^17.3.1",
    "@angular/core": "^17.3.1",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.3.1",
    "@angular/platform-browser": "^17.3.1",
    "@angular/platform-browser-dynamic": "^17.3.1",
    "@angular/router": "^17.3.1",
    "@fortawesome/angular-fontawesome": "^0.14.0",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@fortawesome/fontawesome-pro": "^6.5.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/free-brands-svg-icons": "^6.5.1",
    "@fortawesome/free-regular-svg-icons": "^6.5.1",
    "@fortawesome/free-solid-svg-icons": "^6.5.1",
    "@fullcalendar/common": "^5.11.5",
    "@fullcalendar/core": "^6.1.11",
    "@fullcalendar/daygrid": "^6.1.11",
    "@fullcalendar/interaction": "^6.1.11",
    "@fullcalendar/timegrid": "^6.1.11",
    "@ng-idle/core": "^14.0.0",
    "@ng-idle/keepalive": "^14.0.0",
    "autoprefixer": "^10.4.19",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.2",
    "crypto-js": "^4.2.0",
    "date-fns": "^3.6.0",
    "datejs": "^1.0.0-rc3",
    "file-saver": "^2.0.5",
    "firebase": "^10.3.1",
    "lodash": "^4.17.21",
    "mathjs": "^12.4.2",
    "ng-multiselect-dropdown": "^1.0.0",
    "ng2-charts": "^6.0.0",
    "ng2-pdfjs-viewer": "^17.0.3",
    "ngx-bootstrap": "^12.0.0",
    "primeflex": "^3.2.1",
    "primeicons": "^6.0.0",
    "primeng": "^17.12.0",
    "prismjs": "1.29.0",
    "rxfire": "^6.0.3",
    "rxjs": "^7.5.5",
    "tslib": "^2.4.0",
    "uuid": "^9.0.0",
    "web-animations-js": "^2.3.2",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.2",
    "@angular/cli": "^17.3.2",
    "@angular/compiler-cli": "^17.3.1",
    "@types/datejs": "0.0.36",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/lodash": "^4.17.0",
    "@types/node": "^20.11.30",
    "codelyzer": "^6.0.2",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.0",
    "typescript": "5.4.3"
  }
}
